@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?32v5sh');
  src:  url('fonts/icomoon.eot?32v5sh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?32v5sh') format('truetype'),
    url('fonts/icomoon.woff?32v5sh') format('woff'),
    url('fonts/icomoon.svg?32v5sh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-child:before {
  content: "\e902";color: #f79244;
}
.icon-map-pin:before {
  content: "\e901";color: #f79244;
}
.icon-clock2:before {
  content: "\e94f";color: #f79244;
}
.icon-calendar:before {
  content: "\e953";color: #f79244;
}

.icon-heart:before {
  content: "\e9da";
}
