body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a.gallery {
	/*margin:5px;
	padding:5px;*/
	background:#fff;
	box-shadow:0 0 10px rgba(0,0,0,0.2);
}
a.gallery:hover {
	transition:all .3s;
	transform:scale(1.1);
}

a.custom {
	display:inline-block;
	padding:10px;
	color:#fff;
	background:#000;
	border:3px solid #fff;
	box-shadow:0 0 10px rgba(0,0,0,0.2);
	text-decoration:none;
}
a.custom:hover {
	transition:all .3s;
	transform:scale(1.1);
}
#inline,
.inline {
	padding:15px;
	text-align:left;
}
/*===========================================
 * Media
 *===========================================*/
@media only screen and (max-width: 1024px) {
}


@import url('https://fonts.googleapis.com/css?family=Cabin:400,500,700|Montserrat:400,500,700');
@import 'css/animate.css';
@import 'css/magnific-popup.css';
@import 'css/owl.carousel.min.css';
@import 'css/slick.css';
@import 'css/font-awesome.min.css';
@import 'css/themify-icons.css';
@import 'css/ionicons.min.css';


/* --------------------------
:: 1.0 Base CSS Start
-------------------------- */

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Cabin', sans-serif;
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Cabin', sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.2;
}

.nav{background: #FFCA28!important;border-radius: 100px;margin-bottom: 30px}

.nav-tabs .nav-item {

    margin-bottom: 0px!important;

}

.language a {

    color: #fff!important;
    font-size: 18px;
    

}


.input-group .form-control {

    position: relative;
    z-index: 2;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%!important;
    margin-bottom: 0;

}



/*header*/
.banner {
    width: 100%;
    background-position: 0;
    background-size: cover;
  
  position:relative;
  padding:207px 0; background-image: url(img/bg-img/welcome-bg-3.png); 
}

header{
  position:absolute;
  width:100%;
  top:0;
  z-index:11;
}

.banner-left h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 800;
    line-height: 50px;
    text-shadow: 1px 2px #000;
  margin-bottom:15px;
}
#main_header{ /*background: #f79244;*/
   
    
}
.bgImageHeader{
    background-position: 0;
    background-size: cover;

  background-image: url(img/bg-img/welcome-bg-3.png);
}


.banner-left p {
    color: #fff;
    letter-spacing: 0.5px;
    line-height: 28px;
  margin-bottom:30px;
}

.banner-right {
    padding: 30px;
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 15px;
    /*box-shadow: 1px 1px 18px 1px #0000007a;*/
}

.form-control.rounded {
    border-radius: 100px!important;
    display: block;
    width: 100%;}

.custom-btn {
    width: 100%;
    background: #FFCA28;
    color: #fff;
    letter-spacing: 2.5px;
  transition:0.8 ease;
  border-radius: 100px;
}

.banner-left a {
    background: #e91e63;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    padding: 8px 35px;
    border-radius: 4px;
  transition:0.8 ease;
}

.banner-left a:hover{
  letter-spacing:3px;
  transition:0.8 ease;
}

.custom-btn:hover{
  letter-spacing:3px;
  transition:0.8 ease;
}
.center{ margin: 30px auto; width: 100px;}

.navbar-light .navbar-brand {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    text-shadow: 1px 2px #000;
}

.navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #fff;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link:hover {
    color: #e91e63;
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: #fff;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #e91e63;
}

.dropdown-menu {
    padding: 0px;
}
span.navbar-toggler-icon {
    background-image: url(https://i.ibb.co/1v9M0dZ/menu.png) !important;
    width: 25px;
    height: 25px;
  cursor:pointer;
}
button.navbar-toggler:focus {
    outline: none;
}

a.dropdown-item {
    padding: 10px;
    background: #515156;
    color: #fff;
}

/* Mobile css media query */

@media only screen and (max-width: 800px ){
  .banner-left h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    line-height: 35px;
    text-shadow: 1px 2px #000;
}


.banner{
  padding:120px 0;
  height: auto;
}
.banner-right {
    margin-top: 30px;
}

.nav-color{
  background:#000;
}
.navbar-light .navbar-nav .nav-link{
  padding-left:0;
}

}
/*header*/



/*slider-popup*/
.ui-page {
  outline: none;
}

/* Slider */
.slider-container {
  width: 100%;
  margin: 0 auto;
  height: 400px;
  z-index: 999;
}

.slider-carousel {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.slider {
  display: flex;
  height: 100%;
  flex-shrink: 0;
  transition: all 1s;
}

.slide-panel {
  flex-basis: 20%;
  flex-shrink: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-box {
  position: absolute;
  display: flex;
  color: white;
  z-index: 999;
  /*background: rgba(0, 0, 0, 0.3);*/
  text-align: center;
  border-radius: 2px;
  border: 2px solid #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;

}

.slide-text {
  align-items: center;
  justify-content: center;
  padding: 0 3.5rem;
  font-size: 3.2rem;
  font-weight: 300;
  color: #fff;
}

.slide-img {
  width: 100%;
  min-height: 100%;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.slide-video {
  width: 100%;
  min-height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Slider Controls */
.slider-arrow {
  position: absolute;
  width: 5rem;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
}

.slider-arrow:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.controls-mobile {
  display: none;
}

.prev-slide {
  left: -5rem;
}

.next-slide {
  right: -5rem;
}

.slider-carousel:hover .slider-arrow {
  display: flex;
}

.slider-carousel:hover .prev-slide {
  left: 0;
}

.slider-carousel:hover .next-slide {
  right: 0;
}

.slider-controls i {
  font-size: 3rem;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.slideshow-toggle {
  position: absolute;
  bottom: 30px;
  right: 100px;
  transform: translate(-50%);
  list-style: none;
  opacity: 0;
  padding: 0;
  margin: 0;
  color: #fff;
  transition: all 0.5s;
}

.slider-container:hover .slideshow-toggle {
  opacity: 1;
}

.slideshow-toggle i {
  font-size: 2.2rem;
  margin: 0 .5rem;
  padding: .2rem;
  /*border: 2px solid #fff;*/
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.5s;
}

.slideshow-toggle i:hover {
  background: rgba(0, 0, 0, 0.5);
}

.play-slideshow {
  display: none;
}

.slide-selector {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.slide-selector li {
  width: 14px;
  height: 14px;
  border-radius: 50px;
  margin: 10px;
  border: 2px solid #fff;
  cursor: pointer;
  transition: all 0.5s;
}

.slide-selector li:hover {
  background: rgba(0, 0, 0, 0.5);
}

.slide-selector li.slide-selected {
  background: #fff;
}

/* Slider Modal */
.slider-modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.9);
  padding-top: 2vh;
  transition: all 0.5s;
}

.modal-slide {
  margin: auto;
  display: block;
  width: 88%;
}

.slider-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slider-close:hover, .slider-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* Small Tablet (Slider) */
@media only screen and (max-width: 843px) {
  .slider-controls {
    display: none;
  }
}

/* Tablet (Slider) */
@media screen and (min-width: 560px) and (max-width: 1024px) {
  .modal-slide {
    width: 90%;
  }
}

/* Mobile (Slider) */
@media only screen and (max-width: 560px) {
  .slider-container {
    height: 250px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .slide-text {
    margin: .8rem 1.6rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .slider-controls {
    display: none;
  }
}


/*slider-pop*/

.section_padding_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section_padding_100_50 {
    padding-top: 100px;
    padding-bottom: 50px;
}

.section_padding_100_70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.section_padding_50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section_padding_50_20 {
    padding-top: 50px;
    padding-bottom: 20px;
}

.section_padding_150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.section_padding_200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.bannerall{border:1px #CED4DA solid;border-radius: 5px;}

.section_padding_0_100 {
    padding-top: 90px;

background: #fff;
}

.section_padding_40 {
    padding-top: 40px;

background: #fff;
}


.section_padding_70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.section_padding_0_50 {
    padding-top: 0;
    padding-bottom: 50px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

#scrollUp {
    bottom: 0;
    font-size: 24px;
    right: 30px;
    width: 50px;
    background-color: #f89244;
    color: #fff;
    text-align: center;
    height: 50px;
    line-height: 50px;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

li {
    list-style: none;
}

p {
    color: #726a84;
    font-size: 16px;
    font-weight: 300;
    margin-top: 0;
}

.heading-text > p {
    font-size: 16px;
}

.section-heading > h2 {
    font-weight: 300;
    color: #0ec9bc;
    font-size: 48px;
    margin: 0;
}


.section-heading {
    margin-bottom: 60px;
}

.line-shape-white,
.line-shape {
    width: 80px;
    height: 2px;
    background-color: #fd9142;
    margin-top: 15px;
}

.line-shape {
    margin-left: calc(50% - 40px);
}

.table {
    display: table;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

#preloader {
    overflow: hidden;
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
}

.join-load {
    -webkit-animation: 2000ms linear 0s normal none infinite running colorlib-load;
    animation: 2000ms linear 0s normal none infinite running colorlib-load;
    background: transparent none repeat scroll 0 0;
    border-color: #dddddd #dddddd #fb397d;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    height: 40px;
    left: calc(50% - 20px);
    position: relative;
    top: calc(50% - 20px);
    width: 40px;
    z-index: 9;
}

@-webkit-keyframes colorlib-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes colorlib-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* --------------------------
:: 2.0 Header Area CSS
-------------------------- */


/*search*/
 .searchbar{
    margin-bottom: auto;
    margin-top: auto;
    height: 40px;
    background-color: #f79244;
    border-radius: 30px;
    padding: 0px;
    }

    .search_input{
    color: white;
    border: 0;
    outline: 0;
    background: none;
    width: 0;
    caret-color:transparent;
    line-height: 40px;
    transition: width 0.4s linear;
    }

    .searchbar:hover > .search_input{
    padding: 0 10px;
    width: 300px;
    caret-color:red;
    transition: width 0.4s linear;
    }

    .searchbar:hover > .search_icon{
    background: white;
    color: #e74c3c;
    }

    .search_icon{
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color:white;
    }
/*search*/

.header_area {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0;
    padding: 0 4%;
}

.menu_area .navbar-brand {
    font-size: 72px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    line-height: 1;
    padding: 0;
}


.menu_area .navbar-brand:hover,
.menu_area .navbar-brand:focus {
    color: #fff;
}


.menu_area {
    position: relative;
    z-index: 2;
}


.menu_area #nav .nav-link1 {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 0 15px;

    background: #ffca28;
   border: none;
}





.menu_area #nav .nav-link1:hover {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 0 15px;

    background: #00dcdc;
   
}

.menu_area #nav .nav-link {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 0 15px;

}


.menu_area nav ul li > a:hover {
    color: #fb397d;
}

.sing-up-button {
    text-align: right;
}

.sing-up-button > a {
    color: #fff;
    font-weight: 500;
    display: inline-block;
    border: 2px solid #a883e9;
    height: 50px;
    min-width: 178px;
    line-height: 46px;
    text-align: center;
    border-radius: 24px 24px 24px 0px;
}

.sing-up-button > a:hover {
    background: #fb397d;
    color: #fff;
    border-color: transparent;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

/* stcicky css */

.header_area.sticky {
    background-color: #f79244;
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    height: 85px;
    position: fixed;
    top: 0;
    z-index: 99;
}

.header_area.sticky .menu_area .navbar-brand {
    font-size: 50px;
}

.header_area.sticky .menu_area #nav .nav-link {
    padding: 0 15px;
}
.header_area.sticky .navbar {
    padding: 0;
}


/* --------------------------
:: 3.0 Wellcome Area CSS
-------------------------- */

.wellcome_area {
    background-image: url(img/bg-img/welcome-bg-3.png);
    height: 900px;
    position: relative;
    z-index: 1;
    background-position: bottom center;
    background-size: cover;
}

.wellcome_area:after {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    background-color: #fff;
    content: '';
    bottom: 0;
    z-index: 2;
}

.welcome-thumb {
    width: 45%;
    position: absolute;
    bottom: -130px;
    right: 85px;
    z-index: 9;
}

.welcome-thumb img {
    height: auto;
    width: 100%;
}

.wellcome-heading > h2 {
    font-size: 100px;
    color: #ffffff;
    font-weight: 700;
    position: relative;
    z-index: 3;
}

.get-start-area .email {
    background: #9572e8;
    height: 50px;
    max-width: 260px;
    border: none;
    border-radius: 24px;
    padding: 0px 15px;
}

.form-control::-webkit-input-placeholder {
    color: #838b93;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #838b93;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #838b93;
    opacity: 1;
}

.form-control::placeholder {
    color: #838b93;
    opacity: 1;
}

.get-start-area .email:focus {
    border: none;
    outline-offset: transparent !important;
    border-radius: 30px;
}

.get-start-area .submit {
    background-color: #fb397d;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    border: none;
    height: 50px;
    min-width: 167px;
    line-height: 46px;
    text-align: center;
    border-radius: 24px 24px 24px 0px;
    margin-left: 10px;
}

.get-start-area .submit:hover {
    background: #6f52e5;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.wellcome-heading > p {
    font-size: 38px;
    color: #ffffff;
    font-weight: 700;
    position: relative;
    z-index: 3;
}


.wellcome-heading > h3 {
    font-size: 332px;
    position: absolute;
    top: -134px;
    font-weight: 900;
    left: -12px;
    z-index: -1;
    color: #fff;
    opacity: .1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.wellcome-heading {
    margin-bottom: 100px;
}

/* --------------------------
:: 4.0 Special Area CSS
-------------------------- */

.section-title {
    text-align: center;
    margin-bottom: 3rem;
}

.section-title h3 {
    color:#f89244;
}

.section-title small {
    color: #998a9b;
}


/*  TABS
----------------------*/


.btn-info{background-color: #0CD2D2;color: #fff;border:0!important;border-radius: 100px;}
.btn-info:hover{background-color: #F7941D}

.tab-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
   
    /*box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);*/
    padding: 0 0 0 0;
}

.section {

    padding: 80px 0;

}




.light-bg {
    background-color: #faf6fb;
}




#formfocus .contact_input_area .form-group .form-control:focus {

    color: #495057;
    background-color: #fff;
    border-color: #ff9b80;
    outline: 0;
    box-shadow: 0 0 0 .2rem #ff9b80;

}





@media (max-width:992px) {
    .tab-content {
        padding: 1.5rem;
    }

    .nav-justified .nav-item {

    -ms-flex-preferred-size: 0!important;
    flex-basis: 0!important;}
}

.tab-content p {
    line-height: 1.8;
    font-size: 18px;
}
.t2 p{ margin-bottom: 0px; text-align: left; }
.t2 h5{ text-align: left; margin-bottom: 0rem; font-size: 16px;}

.tab-content h2 {
    margin-bottom: 0.5rem;
}

.nav-tabs {
    border-bottom: 0;
}
.commonFilter{text-align: left;}
#middle-sections{text-align: left;}
.labelInterest{ margin-top:0px;background-color: #ACAFB2;color:#fff;font-size: 15px;padding: 3px 15px;border-radius: 100px; }
.labelInterestSpan{ font-size: 16px;padding: 3px 14px 0 0;}
.dateAclass{padding: 0 0 0 24px;color: #F7941D}
.margin0{margin: 0 0 0 0;}
.padding0{ padding-left: 0; padding-right: 0;}
.div10kids{ border-bottom: 1px #CED4DA solid;padding-bottom:25px;}
.downloadSpecial { background-color: #fff;padding: 80px 0 0 0;}
.contactMail{ color: #726a84;}
.getmyid{margin-top:30px;}
.getmyid p{font-size: 20px;margin: 0 0 5px 0;}
.site-section1aa{ padding: 3em 0;background-color: #fff; }
.containeraa{ border:1px #e9e7e7 solid;padding: 10px;border-radius: 5px;}
#pills-tab{ background-color: none;position: sticky;position: -webkit-sticky;background: #fff;left: 0;top: 0px;z-index: 9;}
#overview span{ font-size: 22px;color: #0CD2D2}
#photo span { font-size: 22px;color: #0CD2D2}
#review span { font-size: 22px;color: #0CD2D2}
.section-headaa{border-bottom: 1px #CED4DA solid;padding-bottom: 20px}
.section-tabaa{border-bottom: 1px #CED4DA solid;padding-bottom: 10px}
.photoaa{font-weight: 300;color: #0ec9bc;font-size:25px;width: 100%}
.imggallery{padding-left: 0;padding-right: 0}
.padding5{ padding-left: 7px; padding-right: 7px;}
.margin5{ margin-right: 5px; margin-left: 5px;}
.margin10{ margin-right: 10px; margin-left: 10px;}
.marginbot10{ margin-bottom: 14px;}
.modal-header .close{padding-top: 0px;margin-top: -33px;}
.galleryslide {height: 183px;}
.width50{ width: 50%; float: left;}
.promocode{margin-bottom: 0;}
.promocodeApplied{clear: left;}
.totalprice{clear: both; font-size: 125%;padding-top: 10px;}
input[type="checkbox"].ml-auto.child_input.float-right{ margin-left: auto!important;}
/* Dropdown Button */
.dropbtn {
  background-color: #ffca28;
  color: white!important;
  padding: 9px 12px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  top: 0;
  margin: 0 10px;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #00DCDC;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;

}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: initial;
}

.dropdown-content a.active {
  background: #3ea4d6;color: #fff;
}

.dropdown-content a.active:hover {
  background: #3ea4d6;color: #fff;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f79244;color: #fff;}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;} 


/*slider*/
.transition-timer-carousel .carousel-caption {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 4%, rgba(0,0,0,0.5) 32%, rgba(0,0,0,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(4%,rgba(0,0,0,0.1)), color-stop(32%,rgba(0,0,0,0.5)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 4%,rgba(0,0,0,0.5) 32%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 4%,rgba(0,0,0,0.5) 32%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 4%,rgba(0,0,0,0.5) 32%,rgba(0,0,0,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 4%,rgba(0,0,0,0.5) 32%,rgba(0,0,0,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    width: 100%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    text-align: left;
    padding-top: 5px;
    padding-left: 15%;
    padding-right: 15%;
}
.transition-timer-carousel .carousel-caption .carousel-caption-header {
    margin-top: 10px;
    font-size: 24px;
}

.carousel-inner {

    position: relative;
    width: 100%;
    overflow: hidden;
    color: #fff!important;text-align: center;

}


.gj-datepicker-bootstrap [role="right-icon"] button {

    width: 38px;
    position: relative;
    border: 1px solid #ced4da;
        border-top-color: rgb(206, 212, 218);
        border-right-color: rgb(206, 212, 218);
        border-bottom-color: rgb(206, 212, 218);
        border-left-color: rgb(206, 212, 218);
        border-left-style: solid;
        border-left-width: 1px;
    height: 38px;

}


.modal {position: fixed;top: 65px;}


/*form*/

.panel-login.login-none{border-color: none;margin: 25px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none}

.panel-login {
    border-color: #ccc;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
}
.panel-login>.panel-heading {
    color: #00415d;
    background-color: #fff;
    border-color: #fff;
    text-align:center;
}
.panel-login>.panel-heading a{
    text-decoration: none;
    color: #666;
    font-weight: bold;
    font-size: 15px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
}
.panel-login>.panel-heading a.active{
    color:#3ea4d6;
    font-size: 18px;
}
.panel-login>.panel-heading hr{
    margin-top: 10px;
    margin-bottom: 0px;
    clear: both;
    border: 0;
    height: 1px;
    background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
    background-image: -ms-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
    background-image: -o-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
}
.panel-login input[type="text"],.panel-login input[type="email"],.panel-login input[type="password"] {
    height: 45px;
    border: 1px solid #ddd;
    font-size: 16px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
}
.panel-login input:hover,
.panel-login input:focus {
    outline:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #ccc;
}
.btn-login {
    background-color: #59B2E0;
    outline: none;
    color: #fff;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border-color: #59B2E6;
}
.btn-login:hover,
.btn-login:focus {
    color: #fff;
    background-color: #53A3CD;
    border-color: #53A3CD;
}
.forgot-password {
    text-decoration: underline;
    color: #888;
}
.forgot-password:hover,
.forgot-password:focus {
    text-decoration: underline;
    color: #666;
}

.btn-register {
    background-color: #1CB94E;
    outline: none;
    color: #fff;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border-color: #1CB94A;
}
.btn-register:hover,
.btn-register:focus {
    color: #fff;
    background-color: #1CA347;
    border-color: #1CA347;
}

/*form*/


.modal-footer1 {

    padding: 15px;
    text-align: center;
    border-top: 1px solid #e5e5e5;

}

.modal-header {

    padding: 15px;
    border-bottom: 1px solid #e5e5e5;justify-content: center;

display: block;

}


.btn-primary:hover {

    color: #fff;

      background-color: #f79244;
    border-color: #f79244;
    

    

}


.fa {

  
    vertical-align: middle!important;

}


.modal-footer {

    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid #e9ecef;
    justify-content: center;

}

.btn.book{border-radius: 1.25rem;padding:0 10px;vertical-align: baseline;}

.summercamp{border-radius: 1.25rem;padding:1px 18px;vertical-align: baseline;background-color: #00dcdc;color: #fff;}

.btn-primary {

    color: #fff;
 

    background-color: #00dcdc;
    border-color: #00dcdc;

}

.multi-carousel {
    opacity: 0;
    padding: 0 25px;
}
.multi-carousel .carousel-control-next,
.multi-carousel .carousel-control-prev {
    width: 25px;
    background: gray;    
}


/*slider*/


.titlebar {
  overflow: hidden;
}
.titlebar .next-back {
  float: right;
  margin: 12px 0;
}
.titlebar .next-back span a {
  font-size: 12px;
  text-transform: uppercase;
  font-family: Poppins;
  color: #222222;
}
.titlebar .next-back span a:hover {
  text-decoration: none;
  color: #888888;
}
.titlebar .next-back span {
  border-right: 1px solid #000;
  padding: 0 8px 0 5px;
  display: inline-block;
  line-height: 10px;
}
.titlebar .next-back span:last-child {
  border-right: none;
  padding-right: 0;
}

/*.carousel-inner > .carousel-item {*/
/*  -webkit-transition: -webkit-transform 1.2s ease-in-out !important;*/
/*  -o-transition: -o-transform 1.2s ease-in-out !important;*/
/*  transition: transform 1.2s ease-in-out !important;*/
/*}*/

.carousel {
  margin-bottom: 0;
  padding: 0 0 0 0;
}

.arrival-product .arrival-item ul {
  margin: 0;
  padding: 0;
}
.arrival-product .arrival-item ul li {
  float: left;
  list-style: none;
  width: 23%;
  overflow: hidden;
  margin-right: 2.6%;
}
.arrival-product .arrival-item ul li:last-child {
  margin-right: 0;
}
/*slider*/


input[type="checkbox"], input[type="radio"] {

    box-sizing: border-box;
    margin: 0 10px 0 0!important;

}

hr {

    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
    border-top: 1px solid #F7941D;

}

.text-info{color: #0CD2D2!important;}

.ul li label.pck{padding: 0 0 0 10px;}


.Whatonforkids{width:  100%;border-bottom: 2px red solid}

/*tabes scroll top*/
.menu li.active span{
    border-bottom: 2px #F7941D solid!important;padding-bottom: 5px;
}

/*tabs scroll top*/  

.nav.backnone {

    background: #FFF !important;
    border-radius: 100px;
    margin-bottom: 30px;

}

.btn-info.bigbutton {background-color: #F7941D;color: #fff;border: 0 !important;border-radius: 100px;padding: 10px 80px;}
.ageroup ul{width: 100%;}
.ageroup ul li span{background-color: #F7941D;color: #fff;border-radius: 10px;padding: 0px 15px;margin: 0 10px 9px 0;
 cursor: pointer;float: left;}
.ageroup ul li span.active{ background-color: #0CD2D2;color: #fff;padding: 0px 15px;border-radius: 10px;margin: 0 10px 9px 0;
 cursor: pointer;}


.galleryslide img{border-radius: 5px;}
.galleryslide .innovative{background-color: #f7941d;
    font-size: 20px;
    color: #fff;
    margin-top: -24px;
    border-radius: 3px;
    padding: 0 15px;
    position: relative;}


.image-bg-1{background: url(img/bg-img/club.png) no-repeat; padding: 111px 0 0 0; background-position: center center; background-size: 114%; margin: 0 0.5%;border-radius: 5px;}

.contentimage{padding: 0 0 0 0;}
.contentimage label.test{background: #1fbce6;color: #fff;height: 70px;border-radius: 10px 10px 100px 100px;
-moz-border-radius: 10px 10px 100px 100px;
-webkit-border-radius: 10px 10px 100px 100px;
border: 0px solid #000000;}

.heading-liitle{
background: rgba(205,207,208,1);
background: -moz-linear-gradient(top, rgba(205,207,208,1) 0%, rgba(255,255,255,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(205,207,208,1)), color-stop(100%, rgba(255,255,255,1)));
background: -webkit-linear-gradient(top, rgba(205,207,208,1) 0%, rgba(255,255,255,1) 100%);
background: -o-linear-gradient(top, rgba(205,207,208,1) 0%, rgba(255,255,255,1) 100%);
background: -ms-linear-gradient(top, rgba(205,207,208,1) 0%, rgba(255,255,255,1) 100%);
background: linear-gradient(to bottom, rgba(205,207,208,1) 0%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdcfd0', endColorstr='#ffffff', GradientType=0 );
padding: 5px 10px 5px 10px;opacity: 0.7;margin-top: 0; }
.image-bg-1 .contentimage .heading-liitle h5{color: #000;margin-top: 0;}
.image-bg-1 .contentimage .heading-liitle p{color: #000; font-size:13px;}
.p-1{padding: .45rem!important}
.padding-right10{padding-right:10px}

.contentimage label.test span.rotate {/* Safari */-webkit-transform: rotate(-90deg);/* Firefox */-moz-transform: rotate(-90deg);/* IE */
-ms-transform: rotate(-90deg);/* Opera */-o-transform: rotate(-90deg);float: left;}

.contentimage span.summercamp{background: #0cd2d2;padding: 6px 18px;border-radius: 25px;font-size: 13px;color: #fff;opacity: 10}

.btn-link:hover {

    color: #f79244;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;

}

.btn-link {

    font-weight: 400;
    color: #f79244;
    background-color: transparent;

}


.btn-outline-secondary {
  color:#131313!important;
  background-color:none!important;
  background-image: none;
  border-color: #ebedf2!important; }




      .gj-datepicker-bootstrap [role="right-icon"] button .gj-icon, .gj-datepicker-bootstrap [role="right-icon"] button .material-icons {

    position: absolute;
    font-size: 21px;
    top: 8px!important;
    left: 9px;

}

@media (min-width: 970px) {
    /* Lower the font size of the carousel caption header so that our caption
    doesn't take up the full image/slide on smaller screens */
    .transition-timer-carousel .carousel-caption .carousel-caption-header {
        font-size: 36px;
    }
}
.transition-timer-carousel .carousel-indicators {
    bottom: 0px;
    margin-bottom: 5px;
}
.transition-timer-carousel .carousel-control {
    z-index: 11;
}
.transition-timer-carousel .transition-timer-carousel-progress-bar {
    height: 5px;
    background-color: #5cb85c;
    width: 0%;
    margin: -5px 0px 0px 0px;
    border: none;
    z-index: 11;
    position: relative;
}
.transition-timer-carousel .transition-timer-carousel-progress-bar.animate{
    /* We make the transition time shorter to avoid the slide transitioning
    before the timer bar is "full" - change the 4.25s here to fit your
    carousel's transition time */
    -webkit-transition: width 4.25s linear;
    -moz-transition: width 4.25s linear;
    -o-transition: width 4.25s linear;
    transition: width 4.25s linear;
}
/*slide end*/

.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    padding: 1rem 1rem;
    border-color: #FFCA28 #FFCA28 #FFCA28!important;
    font-size: 16px;
    color: #fff;
    background: #ffca28;
    border-radius: 100px;
}

.nav-tabs .nav-link.active {
    background: #f79244;
    /*border-top-width: 3px;*/
   /* border-color: #f89244 #faf6fb #FFF;*/
    color: #fff;
    border-radius: 100px;
}



.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {

  border-color: #F7941D!important;

}

.single-icon > i {
    font-size: 36px;
    color: #f49245;
}

.single-special > h4 {
    font-size: 22px;
    color: #0ec9bc;
}

.single-icon {
    margin-bottom: 25px;
}

.single-special > h4 {
    font-size: 22px;
    color: #0ec9bc;
    margin-bottom: 15px;
}

.single-special {
    border: 1px solid #eff2f6;
    padding: 40px;
    border-radius: 40px 40px 40px 0px;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    margin-bottom: 30px;
}

.special_description_content{text-align: center;}


.special_description_content > h2 {
    color: #0ec9bc;
    font-size: 40px;
    margin-bottom: 30px;
}

.single-special:hover {
    -webkit-box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
            box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
}

.single-special p {
    margin-bottom: 0;
}

.app-download-area {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 1;
    flex-basis: 1;
    margin-top: 50px;
}

.app-download-btn a {
    border: 1px solid #ddd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 30px;
    border-radius: 30px 30px 30px 0;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.app-download-btn.active a,
.app-download-btn a:hover {
    border-color: transparent;
    background-color: #f89244;
}

.app-download-btn a i {
    color: #47425d;
    font-size: 24px;
    margin-right: 15px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.app-download-btn a p {
    font-size: 16px;
    line-height: 1;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.app-download-btn a p span {
    font-size: 12px;
    display: block;
}

.app-download-btn:first-child {
    margin-right: 20px;
}

.app-download-btn a:hover i,
.app-download-btn.active a i,
.app-download-btn.active a p,
.app-download-btn a:hover p {
    color: #fff;
}

/* --------------------------
:: 5.0 Features Area CSS
-------------------------- */

.single-feature {
    margin-bottom: 50px;
}

.single-feature > p {
    margin-bottom: 0;
}

.single-feature > i {
    color: #fb397d;
    font-size: 30px;
    display: inline-block;
    float: left;
    margin-right: 10px;
}

.single-feature > h5 {
    font-size: 22px;
    color: #5b32b4;
}

.single-feature > p {
    margin-top: 15px;
}

/* --------------------------
:: 6.0 Video Area CSS
-------------------------- */

.video-area {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    border: 20px solid #fff;
    border-radius: 20px;
    height: 650px;
    -webkit-box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
}

.video-area:after {
    position: absolute;
    content: "";
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    border: 1px solid #f1f4f8;
    z-index: 2;
    border-radius: 20px;
}


.video-area .video-play-btn a {
    width: 80px;
    height: 80px;
    background-color: #fb397d;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 36px;
    line-height: 80px;
    padding-left: 5px;
    border-radius: 50%;
    top: 50%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
}

.video-area .video-play-btn a:hover {
    background-color: #a865e1;
    color: #fff;
}

/* --------------------------
:: 7.0 Cool Facts Area CSS
-------------------------- */

.cool_facts_area {
    position: relative;
    z-index: auto;
    background: #a865e1;
    background: -webkit-linear-gradient(to left, #34d2b9, #f69145);
    background: -webkit-linear-gradient(right, #f69145, #34d2b9);
    background: -webkit-gradient(linear, right top, left top, from(#34d2b9), to(#f69145));
    background: -o-linear-gradient(right, #af6914, #34d2b9);
    background: linear-gradient(to left, #f69145,#34d2b9);
    padding: 100px 0 80px 0;
    top: -100px;
}


.cool_facts_area:after {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background-color: #fff;
    content: ''
}

.cool-facts-content > i {
    color: #ffffff;
    font-size: 36px;
}

.counter-area {
    min-width: 100px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.counter-area > h3 {
    color: #ffffff;
    font-size: 60px;
    margin-bottom: 0;
    line-height: 1;
}

.cool-facts-content > p {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 0;
}

/* --------------------------
:: 8.0 Screenshots Area CSS
-------------------------- */

.app_screenshots_slides {
    padding: 0 2%;
}

.app_screenshots_slides .single-shot {
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85)
}

.app_screenshots_slides .center .single-shot {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.app-screenshots-area .owl-dot {
    height: 11px;
    width: 11px;
    border: 2px solid #a49fba;
    border-radius: 50%;
    display: inline-block;
    margin: 50px 6px;
   }

.app-screenshots-area .owl-dot.active {
    background-color: #f89244;
    border: transparent;
}

.app-screenshots-area .owl-dots {
    height: 100%;
    width: 100%;
    text-align: center;
}

/* --------------------------
:: 9.0 Pricing Plan Area CSS
-------------------------- */

.pricing-plane-area {
    background: #f5f9fc;
}

.single-price-plan {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 30px 30px 30px 0;
    margin-bottom: 30px;
}

.single-price-plan .package-plan {
    padding: 40px 0;
    border-radius: 30px 30px 0 0;
}

.single-price-plan .package-plan > h5 {
    color: #0ec9bc;
    font-size: 18px;
}


.single-price-plan .package-plan .ca-price h4 {
    color: #f89244;
    font-size: 48px;
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    line-height: 1;
}

.single-price-plan .package-plan .ca-price span {
    font-size: 18px;
    color: #f89244;
}

.single-price-plan .plan-button > a {
    background: #726a84;
    padding: 10px 0;
    border-bottom-right-radius: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    display: block;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single-price-plan .package-description > p {
    margin-bottom: 0;
    line-height: 1;
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb;
}

.single-price-plan .package-description > p:first-child {
    border-top: 1px solid #ebebeb;
}

.single-price-plan.active .package-plan,
.single-price-plan.active .plan-button > a,
.single-price-plan:hover .plan-button > a {
    background-color: #f89244;
}

.single-price-plan.active .package-plan .ca-price > h4,
.single-price-plan.active .package-plan .ca-price > span,
.single-price-plan.active .package-plan > h5,
.single-price-plan.active .plan-button > a {
    color: #fff;
}

/* --------------------------
:: 10.0 Testimonials Area CSS
-------------------------- */

.clients-feedback-area .client-name > h5 {
    color: #5b32b4;
    font-size: 22px;
    margin-top: 5px;
}

.clients-feedback-area .star-icon > i {
    font-size: 18px;
    color: #f1b922;
}

.clients-feedback-area .client-description > p {
    max-width: 1010px;
    margin: auto;
    margin-bottom: 15px;
    font-size: 22px;
}

.clients-feedback-area .client > i {
    font-size: 48px;
    color: #897ceb;
    margin-bottom: 30px;
}

.clients-feedback-area .client-name > p {
    color: #b5aec4;
}

.clients-feedback-area .slick-dots {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.clients-feedback-area .slick-dots li button {
    width: 12px;
    height: 12px;
    text-indent: -999999999999999999999999px;
    border: 3px solid #a49fba;
    background-color: #fff;
    border-radius: 50%;
    margin: 5.5px;
    outline: none;
}

.clients-feedback-area .slick-dots li.slick-active button {
    background-color: #fb397d;
    border-color: #fb397d;
}

.clients-feedback-area .slick-slide.client-thumbnail {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 129px;
}


.clients-feedback-area .slick-slide img {
    display: block;
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.clients-feedback-area .slick-slide.slick-current img {
    width: 100px !important;
    height: 100px !important;
}

.clients-feedback-area .slick-arrow {
    position: absolute;
    width: 30px;
    background-color: #fff;
    height: 100%;
    text-align: center;
    font-size: 24px;
    color: #b5aec4;
    display: block;
    line-height: 550%;
    left: 0;
    z-index: 90;
    cursor: pointer;
}

.clients-feedback-area .slick-arrow.fa-angle-right {
    left: auto;
    right: 0;
    top: 0;
}

/* --------------------------
:: 11.0 CTA Area CSS Start
-------------------------- */

.our-monthly-membership {
    background: #a865e1;
    background: -webkit-linear-gradient(to left, #7873ed, #a865e1);
    background: -webkit-linear-gradient(right, #7873ed, #a865e1);
    background: -webkit-gradient(linear, right top, left top, from(#7873ed), to(#a865e1));
    background: -o-linear-gradient(right, #7873ed, #a865e1);
    background: linear-gradient(to left, #7873ed, #a865e1);
}

.membership-description > h2 {
    color: #ffffff;
    font-size: 36px;
}

.membership-description > p {
    color: #ffffff;
    margin-bottom: 0;
}

.get-started-button > a {
    background: #ffffff;
    height: 45px;
    min-width: 155px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    color: #fb397d;
    font-weight: 500;
    border-radius: 23px 23px 23px 0;
}

.get-started-button > a:hover {
    background: #5b32b4;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.get-started-button {
    text-align: right;
}

/* --------------------------
:: 12.0 Team Area CSS Start
-------------------------- */

.member-image {
    margin-bottom: 30px;
    border-radius: 30px 30px 30px 0;
    position: relative;
    z-index: 1;
}

.member-image img {
    border-radius: 30px 30px 30px 0;
}

.member-text > h4 {
    font-size: 22px;
    color: #5b32b4;
}

.member-text > p {
    color: #b5aec4;
    margin-bottom: 0;
}

.team-hover-effects {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(105, 75, 228, 0.8);
    border-radius: 30px 30px 30px 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
}

.single-team-member:hover .team-hover-effects {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.team-social-icon {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.team-social-icon > a {
    font-size: 14px;
    color: #fff;
    background: #9a81d7;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px 10px 10px 0;
    display: block;
    margin: 2.5px;
}

.single-team-member {
    margin-bottom: 50px;
}

/* --------------------------
:: 13.0 Contact Area CSS Start
-------------------------- */

.address-text > p,
.phone-text > p,
.email-text > p {
    font-size: 18px;
    margin-bottom: 10px;
}

.address-text > p > span,
.phone-text > p > span,
.email-text > p > span {
    color: #0ec9bc;
}

.footer-contact-area .line-shape {
    margin-left: 0;
}

.footer-contact-area .heading-text > p {
    margin-top: 50px;
}

.footer-contact-area .heading-text {
    margin-bottom: 50px;
}

#name {
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 25px 25px 25px 0;
}

#email {
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 25px 25px 25px 0;
}

#message {
    border-radius: 25px 25px 25px 0;
}

.btn.submit-btn {
    background: #f89244;
    height: 48px;
    min-width: 185px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 22px 25px 22px 0;
    cursor: pointer;
}

.btn.submit-btn:hover {
    /*background: #5b32b4;*/
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.footer-contact-area {
    background: #f5f9fc;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 30px -20px;
    margin-bottom: 42px;
}
.position-fixed {
    position: relative!important;
    z-index: -101;
    bottom: 11px;
}

.footer-text > p {
    margin-bottom: 50px;
}

/* --------------------------
:: 14.0 Footer Area CSS Start
-------------------------- */

.footer-text > h2 {
    color: #5b32b4;
    font-size: 72px;
    font-weight: 700;
}

.footer-social-icon > a > i {
    background: #f89244;
    font-size: 18px;
    color: #ffffff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-weight: 500;
    border-radius: 10px 10px 10px 0px;
    margin-right: 10px;
}

.footer-social-icon > a > i:hover {
    background: #06e3d4;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.social-icon {
    margin-bottom: 30px;
}

.footer-text {
    margin-bottom: 15px;
}

.footer-text img{width: 50px}

.footer-menu ul li {
    display: inline-block;
}

.footer-menu ul li a {
    border-right: 2px solid #726a84;
    display: block;
    padding: 0 7.5px;
    color: #726a84;
}

.footer-social-icon > a .active {
    background: #f89244;
}

.footer-menu ul li:last-child a {
    border-right: 0 solid #ddd;
}

.footer-social-icon {
    margin-bottom: 30px;
}

.footer-menu {
    margin-bottom: 15px;
}

.copyright-text > p {
    margin-bottom: 0;
}

.copyright-text > p a{
    color:#f89244;
}

.copyright-text > p a:hover{
    color:#0ec9bc;
}

.error{background-color: #FF6600;border:#AA4502 1px solid;padding: .375rem .75rem;color: #FFFFFF;border-radius:4px;}
.success{background-color: #12CC1A;border:#0FA015 1px solid;padding: .375rem .75rem;color: #FFFFFF;border-radius:4px; }

